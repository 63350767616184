/** @jsxImportSource theme-ui */
import { FC } from 'react'
import _ from 'lodash'
import { Flex } from 'theme-ui'
import {
  PrismicPrivacyPolicyPageDataType,
  PrismicStructuredTextType,
  PrismicImageType,
} from 'root/graphql-types'
import { H2, Body10Cols, CircleSmall } from 'src/components/atoms/text'
import { ml } from 'src/gatsby-plugin-theme-ui/utils'
import { motion } from 'framer-motion'
import {
  AnimationScrollYFix,
  yOpacityProps,
  DELAY_AFTER_SHUTTER,
  STAGGER_TIME,
} from 'src/animations'
import SEO from 'src/components/organisms/SEO'
import { setActiveLink } from 'src/components/organisms/NavBar/NavBarDesktop'

type PrivacyPolicyProps = {
  pageContext: PrismicPrivacyPolicyPageDataType & {
    seo_image_1200x1200: PrismicImageType
    alternate_languages: any
    lang: any
  }
  path: string
}

const ALPHABET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'

const Content: FC<{ content: PrismicStructuredTextType }> = ({ content }) => {
  let h2Count = 0
  let h3Count = 0

  const getH2Label = (count: number) => ('0' + count).slice(-2)
  const getH3Label = (count: number) => ALPHABET[count]

  return (
    <div sx={{ mb: 'xl' }}>
      {_.map(content.raw, (item, key) => {
        const { type, text } = item

        if (type === 'paragraph') {
          return <Body10Cols>{text}</Body10Cols>
        }

        if (type === 'heading2') {
          h3Count = 0
          return (
            <div key={key} sx={{ mb: ml('s', 'm'), mt: 'l' }}>
              <Flex sx={{ alignItems: 'top', mb: ml('s', 'm') }}>
                <div sx={{ width: '48px', height: '48px' }}>
                  <CircleSmall
                    sx={{
                      mr: ml('s', 'm'),
                      bg: 'text',
                      color: 'background',
                      borderWidth: '1px',
                      borderColor: 'text',
                      borderStyle: 'solid',
                      transform: ml('translateY(-4%)', 'translateY(4%)'),
                    }}
                  >
                    <span
                      sx={{ transform: 'translateY(-3%)', display: 'block' }}
                    >
                      {getH2Label(++h2Count)}
                    </span>
                  </CircleSmall>
                </div>
                <H2>{text}</H2>
              </Flex>
            </div>
          )
        }

        if (type === 'heading3') {
          return (
            <div key={key} sx={{ mb: ml('s', 'm'), mt: 'l' }}>
              <Flex sx={{ alignItems: 'top', mb: ml('s', 'm') }}>
                <div sx={{ width: '48px', height: '48px' }}>
                  <CircleSmall
                    sx={{
                      mr: ml('s', 'm'),
                      bg: 'background',
                      color: 'text',
                      borderWidth: '1px',
                      borderColor: 'text',
                      borderStyle: 'solid',
                      transform: ml('translateY(-4%)', 'translateY(4%)'),
                    }}
                  >
                    <span
                      sx={{ transform: 'translateY(-3%)', display: 'block' }}
                    >
                      {getH3Label(h3Count++)}
                    </span>
                  </CircleSmall>
                </div>
                <H2>{text}</H2>
              </Flex>
            </div>
          )
        }
        //
      })}
    </div>
  )
}

const PrivacyPolicy: FC<PrivacyPolicyProps> = ({ pageContext, path }) => {
  const { title, content } = pageContext

  setActiveLink(null)
  return (
    <div>
      <SEO
        title={pageContext?.seo_title}
        image={pageContext?.seo_image_1200x1200?.fluid?.src}
        description={pageContext?.seo_description}
        path={path}
        alternate_languages={pageContext.alternate_languages}
        lang={pageContext.lang}
      />
      <AnimationScrollYFix />
      <motion.div {...yOpacityProps({ delay: DELAY_AFTER_SHUTTER })}>
        <H2 sx={{ mt: ml('s', 'l'), mb: ml('m', 'xl') }}>{title}</H2>
      </motion.div>
      <motion.div
        {...yOpacityProps({ delay: DELAY_AFTER_SHUTTER + STAGGER_TIME })}
      >
        {!!content && <Content content={content} />}
      </motion.div>
    </div>
  )
}

export default PrivacyPolicy
